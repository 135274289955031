<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="false"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FDCE48" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Add New Supervisor </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-if="userId" py-2>
      <v-flex xs12>
        <v-card>
          <v-layout wrap justify-center pl-2 pt-2 text-left>
            <v-flex xs12 class="itemValue" style="font-size: 16px">
              Selected User
            </v-flex>
          </v-layout>

          <v-layout wrap justify-start pa-2>
            <v-flex xs5>
              <v-flex xs12 text-left>
                <span class="itemKey">Name</span>
              </v-flex>
              <v-flex xs12 text-left>
                <span class="itemValue">
                  {{ user.firstname }} {{ user.lastname }}
                  <span v-if="!user.firstname">-</span>
                </span>
              </v-flex>
            </v-flex>
            <v-flex xs5>
              <v-flex xs12 text-left>
                <span class="itemKey">Username</span>
              </v-flex>
              <v-flex xs12 text-left>
                <span class="itemValue">
                  {{ user.username }}
                  <span v-if="!user.username">-</span>
                </span>
              </v-flex>
            </v-flex>
            <v-flex xs2>
              <v-btn
                block
                tile
                outlined
                color="#FF1313"
                light
                :ripple="false"
                depressed
                @click="
                  user = {};
                  userId = null;
                "
                class="itemValue"
              >
                Change
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-if="apiaryId" py-2>
      <v-flex xs12>
        <v-card>
          <v-layout wrap justify-center pl-2 pt-2 text-left>
            <v-flex xs12 class="itemValue" style="font-size: 16px">
              Selected Apiary
            </v-flex>
          </v-layout>

          <v-layout wrap justify-start pa-2>
            <v-flex xs3>
              <v-flex xs12 text-left>
                <span class="itemKey">Apiary Id</span>
              </v-flex>
              <v-flex xs12 text-left>
                <span class="itemValue">
                  {{ apiary.apiaryid }}
                </span>
              </v-flex>
            </v-flex>
            <v-flex xs3>
              <v-flex xs12 text-left>
                <span class="itemKey">Hive Count</span>
              </v-flex>
              <v-flex xs12 text-left>
                <span class="itemValue">
                  {{ apiary.colonycount }}
                </span>
              </v-flex>
            </v-flex>
            <v-flex xs4>
              <v-layout wrap justify-center>
                <v-flex xs12 text-left>
                  <span class="itemKey">Location</span>
                </v-flex>
                <v-flex xs12 text-left>
                  <span class="itemValue">
                    {{ apiary.locationname }}
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs2>
              <v-btn
                block
                tile
                outlined
                color="#FF1313"
                light
                :ripple="false"
                depressed
                @click="
                  apiary = {};
                  apiaryId = null;
                "
                class="itemValue"
              >
                Change
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start v-if="!userId">
      <v-flex xs6 class="itemValue" style="font-size: 16px" text-left pt-4>
        Select User
      </v-flex>
      <v-flex xs6>
        <v-layout wrap justify-end>
          <v-flex xs12 sm6 text-right>
            <v-form @submit.prevent="getUsers">
              <v-text-field
                class="searchBox"
                v-model="searchKey"
                prepend-inner-icon="mdi-magnify"
                placeholder="Search Users"
                solo
                flat
                hide-details
                color="#FDCE48"
              ></v-text-field>
            </v-form>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 md6 pa-2 v-for="(item, i) in users" :key="i">
        <AddSupervisorItem
          v-bind:storage="item"
          v-bind:user="user"
          :key="userId"
          @stepper="winStepper"
        />
      </v-flex>
      <v-flex xs12 text-center py-4 pb-12 v-if="users.length < 1">
        <span class="itemKey">No User Found</span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start v-if="userId && !apiaryId">
      <v-flex xs12 class="itemValue" style="font-size: 16px" text-left pt-4>
        Select Apiary
      </v-flex>
      <v-flex xs12 md6 pa-2 v-for="(item, i) in apiaries" :key="i">
        <AddSupervisorApiaryItem
          v-bind:storage="item"
          v-bind:apiary="apiary"
          :key="apiaryId"
          @stepper="winStepper"
        />
      </v-flex>
      <v-flex xs12 text-center py-4 pb-12 v-if="apiaries.length < 1">
        <span class="itemKey">No Apiary Found</span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs6 sm4 xl3 px-2>
        <v-btn
          block
          tile
          outlined
          color="#FF1313"
          light
          :ripple="false"
          depressed
          @click="closeDialoge"
          class="itemValue"
        >
          Cancel
        </v-btn>
      </v-flex>
      <v-flex xs6 sm4 xl3 px-2>
        <v-btn
          block
          tile
          color="#FDCE48"
          light
          :ripple="false"
          depressed
          @click="addSupervisor"
          class="itemValue"
        >
          Add supervisor
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import AddSupervisorItem from "./addSupervisorItem";
import AddSupervisorApiaryItem from "./addSupervisorApiaryItem";
export default {
  components: {
    AddSupervisorItem,
    AddSupervisorApiaryItem,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      currentPage: 1,
      count: 50,
      users: [],
      apiaries: [],
      searchKey: null,
      user: {},
      userId: null,
      apiary: {},
      apiaryId: null,
    };
  },
  beforeMount() {
    this.getUsers();
    this.getApiaries();
  },
  methods: {
    closeDialoge() {
      this.$emit("stepper", {
        type: "Add",
        getData: false,
        addSupervisor: false,
      });
    },
    getUsers() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/searchsupervisor",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          key: this.searchKey,
          page: this.currentPage,
          count: this.count,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.users = response.data.data;
          } else this.users = [];
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getApiaries() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/cluster/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: 0,
          count: 0,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.apiaries = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    winStepper(windowData) {
      if (windowData.type == "SelectUser") {
        this.user = windowData.userData;
        this.userId = this.user._id;
      }
      if (windowData.type == "RemoveUser") {
        this.user = {};
        this.userId = null;
      }
      if (windowData.type == "SelectApiary") {
        this.apiary = windowData.apiaryData;
        this.apiaryId = this.apiary._id;
      }
      if (windowData.type == "RemoveApiary") {
        this.apiary = {};
        this.apiaryId = null;
      }
    },
    addSupervisor() {
      if (!this.userId) {
        this.msg = "Please Select a User";
        this.showSnackBar = true;
        return;
      }
      if (!this.apiaryId) {
        this.msg = "Please Select an Apiary";
        this.showSnackBar = true;
        return;
      }
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/requestforsupervisor",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.userId,
          cluster: this.apiaryId,
          reallocate: false,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.user = {};
            this.userId = null;
            this.apiary = {};
            this.apiaryId = null;
            this.$emit("stepper", {
              type: "Add",
              getData: true,
            });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>
<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card outlined>
          <v-layout wrap justify-center pa-2>
            <v-flex xs12 sm2 text-center align-self-center>
              <v-avatar color="#FFF9C4" size="70">
                <v-img v-if="storage.photo" :src="mediaURL + storage.photo">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#FDCE48"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <span v-else class="itemKey">No Image</span>
              </v-avatar>
            </v-flex>
            <v-flex xs12 sm10 pl-2>
              <v-layout wrap justify-start>
                <v-flex xs6>
                  <v-flex xs12 text-left>
                    <span class="itemKey">Name</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span class="itemValue">
                      {{ storage.firstname }} {{ storage.lastname }}
                      <span v-if="!storage.firstname">-</span>
                    </span>
                  </v-flex>
                </v-flex>
                <v-flex xs6>
                  <v-flex xs12 text-left>
                    <span class="itemKey">Username</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span class="itemValue">
                      {{ storage.username }}
                      <span v-if="!storage.username">-</span>
                    </span>
                  </v-flex>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-end py-2 py-xl-2>
                <v-flex xs6 sm4 px-2>
                  <v-btn
                    v-if="user != storage"
                    block
                    tile
                    small
                    depressed
                    color="#1885f2"
                    :ripple="false"
                    class="itemValue"
                    @click="selectUser"
                  >
                    <span style="color: #ffffff"> Select </span>
                  </v-btn>
                  <v-btn
                    v-else
                    block
                    tile
                    small
                    depressed
                    color="#80ED99"
                    :ripple="false"
                    class="itemValue"
                    @click="removeUser"
                  >
                    <span style="color: #ffffff"> Selected </span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage", "user"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      msg: null,
    };
  },
  methods: {
    selectUser() {
      this.$emit("stepper", {
        type: "SelectUser",
        userData: this.storage,
      });
    },
    removeUser() {
      this.$emit("stepper", {
        type: "RemoveUser",
        userData: this.storage,
      });
    },
  },
};
</script>
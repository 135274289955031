<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card outlined>
          <v-layout wrap justify-center pa-2>
            <v-flex xs12 pl-2>
              <v-layout wrap justify-start>
                <v-flex xs6>
                  <v-flex xs12 text-left>
                    <span class="itemKey">Apiary Id</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span class="itemValue">
                      {{ storage.apiaryid }}
                    </span>
                  </v-flex>
                </v-flex>
                <v-flex xs6>
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left>
                      <span class="itemKey">Location</span>
                    </v-flex>
                    <v-flex xs12 text-left>
                      <span class="itemValue">
                        {{ storage.locationname }}
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-end py-2 py-xl-2>
                <v-flex xs6 sm8 px-2 text-left>
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left>
                      <span class="itemKey">Hive Count</span>
                    </v-flex>
                    <v-flex xs12 text-left>
                      <span class="itemValue">
                        {{ storage.colonycount }}
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs6 sm4 px-2 align-self-center>
                  <v-btn
                    v-if="apiary != storage"
                    block
                    tile
                    small
                    depressed
                    color="#1885f2"
                    :ripple="false"
                    class="itemValue"
                    @click="selectApiary"
                  >
                    <span style="color: #ffffff"> Select </span>
                  </v-btn>
                  <v-btn
                    v-else
                    block
                    tile
                    small
                    depressed
                    color="#80ED99"
                    :ripple="false"
                    class="itemValue"
                    @click="removeApiary"
                  >
                    <span style="color: #ffffff"> Selected </span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage", "apiary"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      msg: null,
    };
  },
  methods: {
    selectApiary() {
      this.$emit("stepper", {
        type: "SelectApiary",
        apiaryData: this.storage,
      });
    },
    removeApiary() {
      this.$emit("stepper", {
        type: "RemoveApiary",
        apiaryData: this.storage,
      });
    },
  },
};
</script>
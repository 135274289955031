<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-dialog v-model="deleteDialogue" width="400">
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: opensanssemibold;
                font-size: 18px;
              "
            >
              Do you want to delete this supervisor ?
            </span>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              outlined
              tile
              block
              depressed
              dark
              color="#C62525"
              @click="deleteSupervisor"
              class="itemValue"
            >
              Yes
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              tile
              block
              depressed
              dark
              color="#1FB436"
              @click="deleteDialogue = false"
              class="itemValue"
            >
              No
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card tile outlined>
          <v-layout wrap justify-center>
            <v-flex xs12 pa-2 align-self-center>
              <v-layout wrap justify-end>
                <v-flex xs12 sm2 md1 xl2 align-self-center text-center>
                  <v-avatar color="#FFF9C4" size="70">
                    <v-img v-if="storage.photo" :src="mediaURL + storage.photo">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="#FDCE48"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>
                </v-flex>
                <v-flex xs12 sm10 md11 xl10 pl-2 align-self-center>
                  <v-layout wrap justify-start>
                    <v-flex xs6 sm4 md4>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span class="itemKey">Username</span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span class="itemValue">
                            {{ storage.username }}
                            <span v-if="!storage.username">-</span>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 sm4 md4>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span class="itemKey">Name</span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span class="itemValue">
                            {{ storage.firstname }} {{ storage.lastname }}
                            <span v-if="!storage.firstname">-</span>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 sm4 md4>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span class="itemKey">Phone</span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span class="itemValue">
                            {{ storage.phone }}
                            <span v-if="!storage.phone">-</span>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 sm6 md4>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span class="itemKey">Email</span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span class="itemValue" v-bind="attrs" v-on="on">
                                {{ storage.email.slice(0, 20) }}
                                <span v-if="storage.email.length > 20">
                                  ...
                                </span>
                                <span v-if="!storage.email">-</span>
                              </span>
                            </template>
                            <span>
                              {{ storage.email }}
                              <span v-if="!storage.email">-</span>
                            </span>
                          </v-tooltip>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 sm3 md4 pl-2>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span class="itemKey">Request Status</span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span class="itemValue">
                            <span v-if="storage.supervisorrequest">
                              Not Accepted
                            </span>
                            <span v-else> Accepted </span>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 sm3 md4>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span class="itemKey">Apiary Id</span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <router-link
                            :to="'/Apiary/' + storage.assignedcluster._id"
                          >
                            <span class="itemValue">
                              {{ storage.assignedcluster.apiaryid }}
                            </span>
                          </router-link>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <!-- <v-flex xs6 sm2 md2 xl2>
                  <v-btn
                    block
                    tile
                    small
                    depressed
                    color="#1885f2"
                    :ripple="false"
                    :to="'/Supervisors/' + storage._id"
                    class="itemValue"
                  >
                    <span style="color: #ffffff"> View </span>
                  </v-btn>
                </v-flex> -->
                <v-flex xs6 sm3 md2>
                  <v-btn
                    block
                    tile
                    small
                    depressed
                    outlined
                    color="#FF1313"
                    :ripple="false"
                    @click="deleteDialogue = true"
                    class="itemValue"
                  >
                    <span style="color: #ff1313"> Delete </span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      msg: null,
      deleteDialogue: false,
    };
  },
  methods: {
    deleteSupervisor() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/removesupervisor",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.storage._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.deleteDialogue = false;
            this.$emit("stepper", {
              type: "Delete",
              getData: true,
              pageResponse: response.data,
            });
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      day = day < 10 ? "0" + day : day;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      return strTime;
    },
  },
};
</script>